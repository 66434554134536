import {Link, Post} from "./Post";
import welcome from "./assets/welcome.png";
import welcomeSm from "./assets/welcome_sm.png";
import {useEffect, useState} from "react";

const months = "january february march april may june july august september november december".split(" ")
const now = new Date();

function today() {
  return `${months[now.getMonth()]} ${now.getDate()}, ${now.getFullYear()}`
}

export function WelcomePost() {
  const [img, setImg] = useState(welcome)

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 640) {
        setImg(welcomeSm)
      } else {
        setImg(welcome)
      }
    }

    handleResize()

    // Attach the event listener to the window object
    window.addEventListener('resize', handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Post imgSrc={img} imgAlt={"welcome image"} imgCover={true}
          title={"welcome!"} date={today()}
          id={"welcome"}>
      <p>
        Hey there! I'm Michael Liu. Below is a catalog of all the projects I've
        worked on since around February 2024!
      </p>
      <br/>
      <p>
        I'll try to post here regularly. If I make a blog post a month, I'll
        have
        achieved my goal. If I make a blog post a week, then I'll probably
        get hired somewhere.
      </p>
      <br/>
      <p>
        The goal of this blog isn't to get hired, though! I just want to be able
        to look back on these projects and see the trajectory of my growth over
        these years, as it's something I've regretted not doing.
      </p>
      <br/>
      <p>
        Well, what are you waiting for? Take a look at my cool stuff!
      </p>
      <br/>
      <p>
        Computer Graphics:
        <ul className={"list-disc list-inside"}>
          <li>
            <Link link={`${window.location.hostname}/#desmos`}>
              a 3d renderer in 2d desmos
            </Link>
          </li>
          <li>
            <Link link={`${window.location.hostname}/#wip`}>
              wip: my custom game engine
            </Link>
          </li>
          <li>
            <Link link={`${window.location.hostname}/#halftone`}>
              halftoning with glsl
            </Link>
          </li>
        </ul>
        <br/>
        Programming Languages:
        <ul className={"list-disc list-inside"}>
          <li>
            <Link link={`${window.location.hostname}/#tosuto`}>
              tōsuto: a scripting language
            </Link>
          </li>
        </ul>
      </p>
    </Post>
  )
}