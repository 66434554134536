import halftone from "./assets/halftone.png";
import halftoneDemo from "./assets/halftoning expl.png";
import {CodeBlock, Link, Post} from "./Post";

export const HalftoningPost = (
  <Post imgSrc={halftone} imgAlt={"halftoning image"} imgCover={true}
        title={"halftoning with glsl"} date={"april 14, 2024"}
        id={"halftone"}>
    <p>
      Recently I've been working on a game using OpenGL and C. I've been
      having great fun making my own data structures and re-implementing
      all the math from scratch, which has allowed me to gain some more
      understanding of how games work.
    </p>
    <br/>1
    <p>
      This game will have a sort of playful, comic-book style feel to it, so
      I first tried to determine what makes comic books look the way they
      do.
    </p>
    <br/>
    <p>
      One thing in common with all comic books is that they're, well,
      printed. This means that they all exhibit a similar visual effect
      that results from printing: halftoning.
    </p>
    <br/>
    <p>
      Printers have a hard challenge to solve. They must be able to print in
      full color (or full grayscale) on a white background, using a limited
      amount of colors of
      ink.
      Halftoning is one method that printers implement to print in this
      manner, and with a little help from <Link
      link={"https://en.wikipedia.org/wiki/Halftone"}>Wikipedia</Link>,
      I learned that the main ideas are as follows:
    </p>
    <ul className={"list-disc list-inside"}>
      <li>
        The background is white. In order to print darker colors, the
        printer
        must use ink to subtract color from what's already there.
      </li>
      <li>
        One such subtractive color model is CMYK, standing for cyan,
        magenta,
        yellow, and black.
      </li>
      <li>
        Ink pools up in circles on the paper, and by controlling the amount
        of ink released by the jet, circles of different radii can be made.
      </li>
      <li>
        When ink of different colors are placed on top of each other, they
        combine to create different colors.
      </li>
      <li>
        The dots of ink can be placed in a grid, and by rotating each grid
        by
        a different amount, the color combinations can be made.
      </li>
    </ul>
    <br/>
    <p>
      At first, I implemented this in GLSL in two stages. First, I'd convert
      the three-element RGB image of the render to a four-element CMYK
      image.
      Second, I'd use a halftoning shader that could turn the CMYK image
      into
      the dots that made up the 'printed' image.
    </p>
    <br/>
    <p>
      Let's go over the code to convert RGB to CMYK. CMYK happens to be
      symmetrical to RGB, so it's easy to convert between the two.
    </p>
    <br/>
    <CodeBlock lang={"glsl"}>
      vec3 color = texture(u_tex, v_uv).rgb;<br/>
      float r = color.r, g = color.g, b = color.b;<br/>
      float k = 1 - max(r, max(g, b));<br/>
      float light = 1 - k;<br/>
      float c = (light - r) / light;<br/>
      float m = (light - g) / light;<br/>
      float y = (light - b) / light;<br/>
      <br/>
      f_color = vec4(c, m, y, k);
    </CodeBlock>
    <br/>
    <p>
      The halftoning shader works as follows: starting with white as the
      output
      color, I'd run through each of the four CMYK 'screens' of dots, and
      depending on whether that pixel is part of a dot, subtract the
      corresponding
      value from the pixel color.
    </p>
    <br/>
    <p>
      Because CMYK is symmetric to RGB, all one has to do to get the CMYK
      effect is
      subtract a color in RGB to get the corresponding
      CMYK
      color.
    </p>
    <ul className={"list-disc list-inside"}>
      <li>
        Cyan: subtract red
      </li>
      <li>
        Magenta: subtract green
      </li>
      <li>
        Yellow: subtract blue
      </li>
      <li>
        Black: subtract white
      </li>
    </ul>
    <br/>
    <p>
      To get the radius of each circle, I computed the average color of four
      pixels that surrounded each circle's center. Then, deciding whether to
      subtract color from that pixel is easy: if the average color is X%
      magenta, then fill the circle that covers X% of the square. Of course,
      it's impossible to get a circle to fill a square without it going
      over, so this calculation must be repeated for each neighboring
      square, too.
    </p>
    <br/>
    <div className={"w-full flex place-content-center"}>
      <img src={halftoneDemo} alt={"halftone demo"}/>
    </div>
    <br/>
    <p>
      This works well on its own, but in order to get a better result, I had
      to blur the image first so that sharp edges weren't rendered badly.
      The
      unmodified code only samples the four corners of each square, so the
      average
      color of all the pixels in the square isn't taken into account. By
      blurring the image beforehand, we can
      save samples and still get the averaged out result when sampling only
      the corners. The end result is what you see at the top of your screen!
    </p>
  </Post>
)