import tosuto from "./assets/tosuto.png";
import {CodeBlock, InlineCode, Link, Post} from "./Post";

export const TosutoPost = (
  <Post imgSrc={tosuto} imgAlt={"tosuto image"} bgColor={"#1e1e2e"}
        title={"tōsuto: a scripting language"} date={"april 21, 2024"}
        id={"tosuto"}>
    <p>
      I've recently been wanting to get back into making programming
      languages again, so I've started up this project to fulfill that
      desire. Though I didn't have a goal for what the project would be, I
      knew that I wanted it to have a really minimal syntax that, given it
      was my first language, made at least some sense with good amounts of
      continuity.
    </p>
    <br/>
    <p>
      I started off by playing around in my code editor and seeing what
      looked okay. Inspired by jai and odin, I made my function declaration
      syntax super simple, with just a single colon between the name and the
      arguments and body. To make the language more concise, I made boolean
      operators a single keystroke because they're used much more often (in
      my daily use) than bitwise operators are. This also makes the ternary
      operator redundant, as one can simply use ands and ors to build a
      substitute.
    </p>
    <br/>
    <p>
      Let's go over some of the features of the language!
    </p>
    <br/>
    <p>
      Variable declarations, reassignments, and accesses are like most
      walrus operator languages. Semicolons are not required at the end of
      lines.
    </p>
    <br/>
    <CodeBlock lang={"tōsuto"}>
      a := 3<br/>
      a = 4
    </CodeBlock>
    <br/>
    <p>
      Function declarations are as simple as typing out the name and args.
      The body can be a single statement with an arrow, or a block with
      curlies. The last statement is implicitly returned, but the <InlineCode>ret</InlineCode> keyword
      can be used to return early.
    </p>
    <br/>
    <CodeBlock lang={"tōsuto"}>
      add : a b -> a + b<br/>
      multiply : a b {'{'}<br/>
      &nbsp;&nbsp;a * b<br/>
      {'}'}
    </CodeBlock>
    <br/>
    <p>
      Because I wanted to try experimenting with different languages,
      identifiers follow the rules specified in Microsoft's <Link link={"https://learn.microsoft.com/en-us/cpp/cpp/identifiers-cpp?view=msvc-170"}>MSVC extensions</Link>.
    </p>
    <br/>
    <CodeBlock lang={"tōsuto"}>
      ﾄｰｽﾄ := "toast"<br/>
      toast := "toast"<br/>
      pain_grillé := "toast"
    </CodeBlock>
    <br/>
    <p>
      Objects are very minimal and are similar to Lua's tables, where
      calling
      a member function with a colon will pass the object as the first
      parameter.
      This first parameter can be called anything, which allows greater
      flexibility
      when making constructors or other special functions.
    </p>
    <br/>
    <CodeBlock lang={"tōsuto"}>
      vec2 := [|<br/>
      &nbsp;&nbsp;new : base x y -> base with [|x = x, y = y|]<br/>
      &nbsp;&nbsp;dot : my other -> my.x * other.x + my.y * other.y<br/>
      &nbsp;&nbsp;// ... other stuff<br/>
      |]<br/><br/>

      vec := vec2:new(1, 2)<br/>
      test_dot = vec:dot(vec2:new(3, 3))
    </CodeBlock>
    <br/>
    <p>
      Note the use of the <InlineCode>with</InlineCode> keyword in the previous example. This
      allows
      easy construction of objects, using the existing object as a template
      for
      the constructed object, and a second object that is appended to the
      first.
      Though this is nowhere near the most efficient way to do things, it's
      definitely one of the easiest to implement!
    </p>
    <br/>
    <p>
      The language uses a custom bytecode and vm that I implemented based on
      the one in <Link link={"https://craftinginterpreters.com/a-bytecode-virtual-machine.html"}>Crafting Interpreters</Link>
      , which I optimized heavily. During this optimization,
      I rewrote the vm to move away from the C++ class based implementation
      that I previously used and returned to the land of C, which, in my opinion, turns out
      to be much nicer and easier to write bytecode interpreters in, anyway.
    </p>
    <br/>
    <p>
      Unlike the implementation in Crafting Interpreters, I adapted the vm from
      my original tree-walk interpreter. This means that my compiler works in
      stages, instead of the CI implementation which lexed, parsed, and compiled
      the source file in one step. The compiler works remarkably similarly to
      the tree-walk interpreter as a result, working recursively down the tree
      of nodes. However, instead of running the code, it spits out bytecode,
      an example of which can be seen below.
    </p>
    <br/>
    <CodeBlock lang={"tosuto bytecode"}>
      anonymous:<br/>
      0000 lit_16&nbsp;&nbsp;&nbsp;&lt;function ﾌｨﾎﾞ&gt;<br/>
      0003 glob_d&nbsp;&nbsp;&nbsp;ﾌｨﾎﾞ<br/>
      0006 glob_g&nbsp;&nbsp;&nbsp;ﾌｨﾎﾞ<br/>
      0009 lit_8&nbsp;&nbsp;&nbsp;&nbsp;9.000000<br/>
      0011 call&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1<br/>
      0013 pop<br/>
      0014 ret<br/>
      <br/>
      anonymous.ﾌｨﾎﾞ:<br/>
      0000 loc_g&nbsp;&nbsp;&nbsp;&nbsp;1<br/>
      0003 lit_8&nbsp;&nbsp;&nbsp;&nbsp;2.000000<br/>
      0005 lt<br/>
      0006 jmpf&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(6->13)<br/>
      0009 pop<br/>
      0010 loc_g&nbsp;&nbsp;&nbsp;&nbsp;1<br/>
      0013 jmpf&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(13->19)<br/>
      0016 jmp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;23&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(16->42)<br/>
      0019 pop<br/>
      0020 glob_g&nbsp;&nbsp;&nbsp;ﾌｨﾎﾞ<br/>
      0023 loc_g&nbsp;&nbsp;&nbsp;&nbsp;1<br/>
      0026 ld_1<br/>
      0027 sub<br/>
      0028 call&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1<br/>
      0030 glob_g&nbsp;&nbsp;&nbsp;ﾌｨﾎﾞ<br/>
      0033 loc_g&nbsp;&nbsp;&nbsp;&nbsp;1<br/>
      0036 lit_8&nbsp;&nbsp;&nbsp;&nbsp;2.000000<br/>
      0038 sub<br/>
      0039 call&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1<br/>
      0041 add<br/>
      0042 ret
    </CodeBlock>
    <br/>
    <p>
      That's as far as I've gone with the project so far. I still have ideas
      for things I want to do with it, though, ranging from trying to write
      a more complete standard library for it to making an ahead-of-time
      compiler for it.
    </p>
  </Post>
)